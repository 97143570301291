import {
  Anchor,
  Button,
  Divider,
  Group,
  Loader,
  Paper,
  PaperProps,
  PasswordInput,
  Stack,
  Text,
  TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDocumentTitle } from '@mantine/hooks';
import { emailValidator } from '../Validators';
import { useJwtState } from '../States/jwtState';
import { ApiError } from '../../Errors';
import Alert from '../AlertWrapper';
import { GoogleButton } from '../SocialButton/GoogleButton';
import { AuthSignIn, cognitoSignin } from '../../services/AuthenticationService';
import { NoAuth } from '../Auth';

export function Signin (props: PaperProps) {
  const [displayLoader, setDisplayLoader] = useState(false);
  const [apiError, setApiError] = useState<ApiError | any>();
  const { setJwtState } = useJwtState();

  useDocumentTitle(`Sign in`);

  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: emailValidator,
    }
  });

  const onSubmit = async () => {
    // e.preventDefault();
    handleValues(form.values)
    handleErrors(form.errors);
  }

  const handleValues = async (values: typeof form['values']) => {

    setDisplayLoader(true);
    const formData: AuthSignIn = {
      email: values.email.trim(),
      password: values.password,
    }

    try {
      const {accessToken} = await cognitoSignin(formData);
      setJwtState(accessToken);

      navigate('/home');
    } catch (err) {
      setDisplayLoader(false);
      setApiError(err);
    }
  }

  const handleErrors = (error: typeof form['errors']) => {
    setDisplayLoader(false);
    setApiError(error);
  }

  return (
    <NoAuth>
      <Paper radius="md" p="xl" {...props} maw={512} ml="lg" style={{ marginLeft: 'auto', marginRight: 'auto'}} >
        <Text size="lg">
          Welcome, sign-in with
        </Text>

        <form onSubmit={form.onSubmit(onSubmit)}>
          <Stack pt="md">
            <TextInput
              required
              label="Email"
              placeholder="hello@browsealarm.com"
              value={form.values.email}
              onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
              error={form.errors.email && 'Invalid email'}
            />

            <div>
              <Group>
                <Text component="label" htmlFor="password" size="sm" aria-required>
                  Password
                </Text>
              </Group>

              <PasswordInput
                required
                placeholder="Your password"
                value={form.values.password}
                onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                error={form.errors.password && 'Password should include at least 12 characters'}
              />
            </div>
          </Stack>

          <Group mt="xl">
            <Button type="submit" size='md' fullWidth>
              Sign in
            </Button>
            {displayLoader && <Loader variant='dots' />}
          </Group>
          <Group mt="sm" justify="flex-end">

            <Anchor size="xs"
              href="/signin/forgot"
              onClick={(event) => {
                event.preventDefault();
                navigate('/signin/forgot');
              }}
              >
                Need help?
            </Anchor>
            <Anchor size="xs"
              href="/signup"
              onClick={(event) => {
                event.preventDefault();
                navigate('/signup');
              }}
              >
                Join now
              </Anchor>
            </Group>
          <Alert messages={apiError?.errors} title={apiError?.message} />
          {/* <Group position="right" mt="xl">
            <span color='dimmed' style={{fontSize: "0.75rem"}}>
                <Link to={'/signin/forgot'}>Forgot password?</Link>
            </span>
          </Group> */}
        </form>

        <Divider label="OR" labelPosition="center" my="lg" />

        <Group grow mb="md" mt="md">
          <GoogleButton radius="xs">Sign-in with Google</GoogleButton>
        </Group>

      </Paper>
    </NoAuth>
  );
}
