import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Group,
  Loader,
  Paper,
  PaperProps,
  PasswordInput,
  Popover,
  Progress,
  Stack,
  Text,
  TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDocumentTitle } from '@mantine/hooks';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ApiError } from '../../Errors';
import { emailValidator, passwordValidator } from '../Validators';
import { GoogleButton } from '../SocialButton/GoogleButton';
import _ from 'lodash';
import AlertWrapper from '../AlertWrapper';
import { AuthSignUp, postSignupFormData } from '../../services/AuthenticationService';
import { LOCALSTORAGE } from '../LocalSorage';
import { NoAuth } from '../Auth';



const getPasswordColor = (strength: number) => {
  if (strength === 100) {
    return 'teal';
  } else if (strength > 50) {
    return 'yellow';
  } else {
    return 'red';
  }
}

export function SignupPage (props: PaperProps) {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(false);
  const [apiError, setApiError] = useState<ApiError>();
  useDocumentTitle(`Sign up`);


  const navigate = useNavigate();

  const nagivateToVerify = () => {
    navigate('/signup/verify');
  }

  const form = useForm({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      terms: false,
    },

    validate: {
      email: emailValidator,
      password: passwordValidator
    },
  });

  const onSubmit = async () => {
    handleValues(form.values)
    handleErrors(form.errors);
  }

  const handleValues = async (values: typeof form['values']) => {

    setDisplayLoader(true);
    const formData: AuthSignUp = {
      email: values.email.trim(),
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      password: values.password,
    }
    localStorage.setItem(LOCALSTORAGE.DATA, JSON.stringify(_.omit(formData, ["password"])));
    localStorage.setItem(LOCALSTORAGE.AUTH_DATA, JSON.stringify(_.omit(formData, ["firstName", "lastName", "password"])));
    try {
      await postSignupFormData(formData);
      nagivateToVerify()
    } catch (err) {
      setDisplayLoader(false);
      if (err instanceof ApiError) {
        setApiError(err);
      } else {
        setApiError({
          name: "SignupError",
          message: "Unable to complete Sign up!",
          errors: ["Contact us for help support@browsealarm.com"]
        })
      }
    }
  }

  const handleErrors = (error: typeof form['errors']) => {
  }

  const passwordStrength = getPasswordStrength(form.values.password);
  let passwordColor = getPasswordColor(passwordStrength);

  let isSignupButtonDisabled = (
    !form.isValid('email')
    || !form.isValid('firstName')
    || !form.values.lastName
    || !form.values.terms
    || (passwordStrength !== 100)
  )

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement key={requirement.label} label={requirement.label} meets={requirement.re.test(form.values.password)} />
  ));


  return (
    <NoAuth>
      <Paper radius="md" p="xl" {...props} maw={512} ml="lg" style={{ marginLeft: 'auto', marginRight: 'auto' }} >
        <Text size="lg">
          Welcome, sign-up with
        </Text>

        <form onSubmit={form.onSubmit(onSubmit)}>

            <Stack pt="md">
              <Group grow >
                <TextInput
                  required
                  label="First Name"
                  placeholder="Dean"
                  value={form.values.firstName}
                  onChange={(event) => form.setFieldValue('firstName', event.currentTarget.value)}
                />

                <TextInput
                  required
                  label="Last Name"
                  placeholder="Smith"
                  value={form.values.lastName}
                  onChange={(event) => form.setFieldValue('lastName', event.currentTarget.value)}
                />
              </Group>

              <TextInput
                required
                label="Email"
                placeholder="hello@browsealarm.com"
                value={form.values.email}
                onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                error={form.errors.email && 'Invalid email'}
              />

              <Box mb={popoverOpened ? '190px' : '0'}>
                <Popover opened={popoverOpened} position="bottom" width="target" transitionProps={{ transition: 'pop', duration: 500, exitDuration: 500 }}>
                  <Popover.Target>
                    <div
                      onFocusCapture={() => setPopoverOpened(true)}
                      // onBlurCapture={() => setPopoverOpened(false)}
                    >
                      <PasswordInput
                        required
                        label="Password"
                        value={form.values.password}
                        onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                      />
                    </div>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Progress color={passwordColor} value={passwordStrength} size={5} mb="xs" />
                    <PasswordRequirement label="Includes at least 8 characters" meets={form.values.password.length >= 8} />
                    {checks}
                  </Popover.Dropdown>
                </Popover>
              </Box>

              <Checkbox
                label="I accept terms and conditions"
                checked={form.values.terms}
                onChange={(event) => form.setFieldValue('terms', event.currentTarget.checked)}
              />
            </Stack>

            <Group  mt="xl">
              <Button type="submit" size='md' fullWidth disabled={isSignupButtonDisabled}>
                Register
              </Button>
              {displayLoader && <Loader variant='dots'/>}
            </Group>
            <AlertWrapper messages={apiError?.errors} title={apiError?.message}/>
            <Group  mt="xl">

              <span color='dimmed' style={{fontSize: "0.75rem"}}>
                <Link to={'/signin'}>Already have an account? Sign in</Link>
              </span>
            </Group>
        </form>

        <Divider label="or" my="lg" />

        <Group grow mb="md" mt="md">
          <GoogleButton radius="xs">Sign-up with Google</GoogleButton>
        </Group>

      </Paper>
    </NoAuth>
  );
}

const requirements = [
  { re: /\d/, label: 'Includes number' },
  { re: /[a-z]/, label: 'Includes lowercase letter' },
  { re: /[A-Z]/, label: 'Includes uppercase letter' },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol ($&+,:;=?@#|'<>.^*()%!-)" },
];

function getPasswordStrength(password: string) {
  if (password.length === 0) {
    return 0;
  }

  let multiplier = password.length >= 8 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 20);
}

function PasswordRequirement({ meets, label }: { meets: boolean; label: string }) {
  return (
    <Text component="div" c={meets ? 'teal' : 'red'} mt={5} size="sm">
      <Center inline>
        {meets ? <IconCheck size="0.9rem" stroke={1.5} /> : <IconX size="0.9rem" stroke={1.5} />}
        <Box ml={7}>{label}</Box>
      </Center>
    </Text>
  );
}
