import AppShell from './Components/AppShell';
import { useEffect } from 'react';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Notifications } from '@mantine/notifications';

const queryClient = new QueryClient();


export default function App() {
  useEffect(() => {
    document.title = "BrowseAlarm.com";
  }, []);

  return (
    <div className="App">
      {/* <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}> */}
        <QueryClientProvider client={queryClient}>
          <MantineProvider>
            <ModalsProvider>
              <Notifications position="top-right" />
              <AppShell />
            </ModalsProvider>
          </MantineProvider>
        </QueryClientProvider>
      {/* </ColorSchemeProvider> */}
    </div>
  )
}
