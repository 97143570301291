// useJwtLoader.js
import { useState, useEffect } from 'react';
import { useJwtState } from './jwtState';

// this provides an additional isJwtLoading state to indicate whether the jwt is being loaded from localStorage
const useJwtLoader = () => {
  const [isJwtLoading, setIsJwtLoading] = useState(true);
  const { jwtValue } = useJwtState();

  useEffect(() => {
    setIsJwtLoading(false);
  }, [jwtValue]);

  return { jwtValue, isJwtLoading };
};

export default useJwtLoader;
