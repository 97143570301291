import { Alert } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import Parser from 'html-react-parser';

import React from 'react';

interface AlertProps {
  messages?: Array<string>;
  title?: string;
}

const AlertWrapper: React.FC<AlertProps> = ({ messages, title }) => {
  if (!messages && !title) {
    return null;
  }
  return (
    <>
      <Alert icon={<IconX size="1rem" />} color="red" mt={'sm'}>
        {title}
        {messages && messages.length > 0 && (
          <ul>
            {messages.map((message, index) => (
              <li key={index}>{Parser(message)}</li>
            ))}
          </ul>
        )}
      </Alert>
    </>
  );
}


export default AlertWrapper;
