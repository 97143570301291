import { Table, Group, Text, Loader } from '@mantine/core';
import { AlarmActions } from './AlarmActions';
import { AddNewAlarm } from './AddNewAlarm';
import { useDomainAlarmState } from '../States/domainAlarmState';
import { getDomainAlarms } from '../../services/AlarmService';
import { useQuery } from 'react-query';
import { AppError } from "../AppError";
import { updateLocalStorageData } from '../LocalSorage';



export function DomainAlarmWrapper () {

  const { domainAlarms, setDomainAlarmState } = useDomainAlarmState();

  const { isLoading, isError } = useQuery('domainAlarms', getDomainAlarms, {
    onSuccess: (data) => {
      setDomainAlarmState(data);
      updateLocalStorageData(data);
    }
  });

  if (isLoading) {
    return <Loader type="bars" size={'sm'} />
  }

  if (isError) {
    return   (
      <AppError message={"Unable to load domain alarms. Please try again later!"}/>
    )
  }

  let rows: JSX.Element[] = [];

  if (domainAlarms && domainAlarms.length > 0) {
    rows = domainAlarms.map((alarm) => (
      <Table.Tr key={alarm.id}>
        <Table.Td>
          <Group gap="sm">
            <Text fz="sm" fw={500}>
              {alarm.domain}
            </Text>
          </Group>
        </Table.Td>
        <Table.Td>
          <AlarmActions alarm={alarm}/>
        </Table.Td>
      </Table.Tr>
    ));
  }

  const renderContent = () => {
    if (!domainAlarms || domainAlarms.length === 0) {
      return <Text fz="sm" fw={500}>No alarms yet!</Text>;
    } else {
      return (
        <Table verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Domain</Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      );
    }
  };


  return (
    <>
      <AddNewAlarm />
      {renderContent()}
    </>
  );
}
