import { DomainAlarmModel } from "../Models/DomainAlarmModel";
import { KeywordAlarmModel } from "../Models/KeywordAlarmModel";

export enum LOCALSTORAGE {
  DATA = "browsealarm.data",
  AUTH_DATA = "browsealarm.authSigninData"
};

export interface LocalStorageData {
  domainAlarms?: DomainAlarmModel[];
  keywordAlarms?: KeywordAlarmModel[];
}

export function updateLocalStorageData(input: DomainAlarmModel | DomainAlarmModel[] | KeywordAlarmModel | KeywordAlarmModel[]) {
  try {
    const localStorageData = localStorage.getItem(LOCALSTORAGE.DATA);

    if (localStorageData) {
      let parsedData = JSON.parse(localStorageData) as (DomainAlarmModel | KeywordAlarmModel)[];

      if (Array.isArray(input)) {
        // If data is an array, replace the entire alarms with the new array
        parsedData = input;
      } else {
        // If data is an object, find and replace just that object in the domainAlarms array
        const index = parsedData.findIndex(alarm => alarm.id === input.id);
        if (index !== -1) {
          parsedData[index] = input;
        } else {
          // If the object doesn't exist in the array, you might want to add it or handle this case differently
          parsedData.push(input);
        }
      }

      localStorage.setItem(LOCALSTORAGE.DATA, JSON.stringify(parsedData));
    } else {
      // If localStorage does not have existing data, initialize with the new data (as an array if it's not already)
      const newData = Array.isArray(input) ? input : [input];
      localStorage.setItem(LOCALSTORAGE.DATA, JSON.stringify(newData));
    }
  } catch (error) {
    console.error('Error updating local storage data:', error);
  }
}
