import { Alert } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { ApiError } from '../Errors';

export function AppError({message}: { message : string}) {
  const apiError = new ApiError(message);

  const icon = <IconInfoCircle />;
  return (
    <Alert variant="light" color="red" radius="xs" title="Uh oh!" icon={icon}>
      {apiError.message}
    </Alert>
  );
}
