import { KeywordAlarmModel } from '../../Models/KeywordAlarmModel';
import { DomainAlarmModel } from '../../Models/DomainAlarmModel';

import { ActionIcon, rem, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { deleteDomainAlarm } from '../../services/AlarmService';
import { useDomainAlarmState } from '../States/domainAlarmState';
import { IconTrash } from '@tabler/icons-react';
import { updateLocalStorageData } from '../LocalSorage';

interface DeleteAlarmProps {
  alarm: DomainAlarmModel | KeywordAlarmModel;
}


export function DeleteAlarm ( {alarm} : DeleteAlarmProps) {

  const { domainAlarms, setDomainAlarmState } = useDomainAlarmState();

  const deleteAlarm = async () => {
    const deleteData = {
      id: alarm.id
    }
    await deleteDomainAlarm(deleteData);
    const updatedAlarms = [...domainAlarms].filter((alarm) => alarm.id !== deleteData.id);
    setDomainAlarmState(updatedAlarms);
    updateLocalStorageData(updatedAlarms);
  }

  const openDeleteModal = () => {
    modals.openConfirmModal({
      title: `Delete ${alarm.domain}?`,
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete {alarm.domain}? This action is destructive and you will have
          to contact support to restore your data.
          </Text>
      ),
      labels: { confirm: 'Delete', cancel: "Cancel" },
      confirmProps: { color: 'red' },
      onCancel: () => {},
      onConfirm: () => deleteAlarm(),
    });
  }

return (
    <ActionIcon variant="subtle" color="red" onClick={openDeleteModal}>
       <IconTrash style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
    </ActionIcon>
  )
}
