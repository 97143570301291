import { IsUUID, IsOptional, IsUrl, IsObject, IsArray, IsEnum, IsString, MaxLength } from "class-validator";
import axios, { AxiosResponse } from "axios";
import { ApiError, ErrorResponse } from "../Errors";
import { DOMAIN_ALARM, } from "../utils/constants";
import { axiosInstance } from "../utils/axios";
import { DomainAlarmModel } from "../Models/DomainAlarmModel";
import { AlarmType } from "../Models";

const ID_NOT_VALID = "id is not valid";

class BaseAlarmBody {
  @IsOptional()
  @IsUUID(4)
  id?: string;

  @IsOptional()
  @IsUrl({})
  domain?: string;

  @IsOptional()
  @IsObject()
  settings?: Record<string, string | string[]>;

  @IsEnum(AlarmType)
  alarmType?: AlarmType;

  @IsString()
  @MaxLength(160)
  notificationText?: string;
}

export class GetAlarm extends BaseAlarmBody {
  @IsUUID(4, { message: ID_NOT_VALID})
  id: string
}

export class DeleteAlarm extends GetAlarm {}

class BaseKeywordAlarmBody extends BaseAlarmBody {
  @IsOptional()
  @IsArray()
  keywords?: string[];
}

export class CreateKeywordAlarmBody extends BaseKeywordAlarmBody {
  @IsUrl({})
  override domain: string;

  @IsArray()
  override keywords: string[];
}

export class UpdateKeywordAlarmBody extends BaseKeywordAlarmBody {

}

// domain alarms

export class CreateDomainAlarmBody extends BaseAlarmBody {
  @IsUrl({})
  override domain: string;

  @IsEnum(AlarmType)
  alarmType: AlarmType;

  @IsString()
  @MaxLength(160)
  notificationText: string;
}

export class ModifyDomainAlarmBody extends BaseAlarmBody {
  @IsUUID(4, {})
  id: string;
}

export async function getDomainAlarms(): Promise<DomainAlarmModel[]> {
  try {
    const response: AxiosResponse<DomainAlarmModel[]> = await axiosInstance.get(`${DOMAIN_ALARM}`);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError<ErrorResponse>(error) && error.response?.data) {
      const { message, rawErrors } = error.response?.data;
      throw new ApiError(message, rawErrors);
    } else {
      throw new ApiError('Get domain alarms request failed');
    }
  }
}

export async function createDomainAlarm(alarm: CreateDomainAlarmBody): Promise<DomainAlarmModel> {
  try {
    const response: AxiosResponse<DomainAlarmModel> = await axiosInstance.post(`${DOMAIN_ALARM}`, alarm);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError<ErrorResponse>(error) && error.response?.data) {
      const { message, rawErrors } = error.response?.data;
      throw new ApiError(message, rawErrors);
    } else {
      throw new ApiError('Create domain alarm request failed');
    }
  }
}

export async function deleteDomainAlarm(alarm: DeleteAlarm): Promise<void> {
  try {
    await axiosInstance.delete(`${DOMAIN_ALARM}/${alarm.id}`);
  } catch (error: unknown) {
    if (axios.isAxiosError<ErrorResponse>(error) && error.response?.data) {
      const { message, rawErrors } = error.response?.data;
      throw new ApiError(message, rawErrors);
    } else {
      throw new ApiError('Delete domain alarm request failed');
    }
  }
}

export async function modifyDomainAlarm(alarm: ModifyDomainAlarmBody): Promise<DomainAlarmModel> {
  try {
    const response: AxiosResponse<DomainAlarmModel> = await axiosInstance.put(`${DOMAIN_ALARM}/${alarm.id}`, alarm);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError<ErrorResponse>(error) && error.response?.data) {
      const { message, rawErrors } = error.response?.data;
      throw new ApiError(message, rawErrors);
    } else {
      throw new ApiError('Delete domain alarm request failed');
    }
  }
}
