import { Group } from '@mantine/core';
import { DomainAlarmModel } from '../../Models/DomainAlarmModel';
import { KeywordAlarmModel } from '../../Models/KeywordAlarmModel';
import { DeleteAlarm } from './DeleteAlarm';
import { ModifyAlarm } from './ModifyAlarm';

interface AlarmSettingsProps {
  alarm: DomainAlarmModel | KeywordAlarmModel;
}

export function AlarmActions ( {alarm} : AlarmSettingsProps) {

  return (
    <Group gap={0} justify="flex-end">
      <ModifyAlarm alarm={alarm}/>
      <DeleteAlarm alarm={alarm}/>
    </Group>
  );
}
