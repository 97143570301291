import { Button, Modal } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { DomainAlarm } from './DomainAlarm';


export function AddNewAlarm () {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
    <Modal opened={opened} onClose={close} title="Add new alarm" centered>
        <DomainAlarm close={close}/>
    </Modal>

    <Button color="green.5" variant="filled" fullWidth onClick={open}>
      <IconPlus />
    </Button>
    </>
  );
}
