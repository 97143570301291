import {
  Anchor,
  Button,
  CheckIcon,
  Group,
  Loader,
  Paper,
  PaperProps,
  PinInput,
  Stack,
  Text,
  TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDocumentTitle } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { ApiError } from '../../Errors';
import AlertWrapper from '../AlertWrapper';
import { codeValidator, emailValidator } from '../Validators';
import { AuthSignIn, AuthSignUpVerify, postResendVerifyFormData, postSignupVerifyFormData } from '../../services/AuthenticationService';
import { SignupComplete } from './SignupComplete';
import { LOCALSTORAGE } from '../LocalSorage';
import { NoAuth } from '../Auth';

export function SignupVerifyPage (props: PaperProps) {
  const [displayLoader, setDisplayLoader] = useState(false);
  const [signupComplete, setSignupComplete] = useState(false);
  const [apiError, setApiError] = useState<ApiError>();
  const [authSigninData, setAuthSigninData] = useState<AuthSignIn>();
  const [resent, setResent] = useState(false);
  const [displayResendLoader, setDisplayResendLoader] = useState(false);

  useDocumentTitle(`Verify Email`);

  const form = useForm({
    initialValues: {
      code: '',
      email: authSigninData?.email || '',
    },

    validate: {
      code: codeValidator,
      email: emailValidator,
    }
  });

  useEffect(() => {
    const storedAuthData = localStorage.getItem(LOCALSTORAGE.AUTH_DATA);
    if (storedAuthData) {
      const signupData = JSON.parse(storedAuthData);
      setAuthSigninData(signupData);
      form.setValues({
        email: signupData.email,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onSubmit = async () => {
    handleValues(form.values);
    handleErrors(form.errors);
  }

  const resendVerification = async () => {
    setResent(false);
    setDisplayResendLoader(true);
    await postResendVerifyFormData({ email: authSigninData!.email})
    setDisplayResendLoader(false);
    setResent(true);
  }

  const handleValues = async (values: typeof form['values']) => {
    setDisplayLoader(true);
    const formData: AuthSignUpVerify = {
      email: values.email.trim(),
      code: values.code.trim(),
    }

    try {
      await postSignupVerifyFormData(formData);
      setDisplayLoader(false);
      setSignupComplete(true);
    } catch (err) {
      setDisplayLoader(false);

      if (err instanceof ApiError) {
        setApiError(err);
      } else {
        setApiError({
          name: "SignupVerifyError",
          message: "Unable to verify code!",
          errors: ["Contact us for help - support@browsealarm.com"]
        })
      }
    }
  }

  const handleErrors = (error: typeof form['errors']) => {
  }

  let isSubmitButtonDisabled = (
    !form.isValid('email')
    || !form.isValid('code')
    || !form.values.email
    || form.values.code.length !== 6
  );


  return (
    <NoAuth>
      <Paper radius="md" p="xl" {...props} maw={512} ml="lg" style={{ marginLeft: 'auto', marginRight: 'auto' }} >
        <Text size="lg">
          Please verify the six-digit code we provided in your email
        </Text>

        <form onSubmit={form.onSubmit(onSubmit)}>

            <Stack pt="md">
              <Group>
                <PinInput
                  oneTimeCode
                  aria-label="One time code"
                  onChange={(e) => form.setFieldValue('code', e)}
                  length={6}
                />
              </Group>
              <TextInput
                required
                label="Email"
                placeholder="hello@browsealarm.com"
                value={form.values.email}
                onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                error={form.errors.email && 'Invalid email'}
              />
            </Stack>

            <Group mt="xl" justify="flex-end">
              <Anchor size="xs"
                href="#"
                onClick={(event) => {
                  resendVerification();
                }}
                >
                Resend verification code
              </Anchor>
                {displayResendLoader && <Loader variant='dots' size="xs" />}
                {resent && <CheckIcon size={15} />}
            </Group>
            <Group mt="sm">
              <Button type="submit" size='md' fullWidth disabled={isSubmitButtonDisabled}>
                Submit
              </Button>
              {displayLoader && <Loader variant='dots'/>}
            </Group>
            {signupComplete &&  <SignupComplete />}
            <AlertWrapper messages={apiError?.errors} title={apiError?.message} />
        </form>
      </Paper>
    </NoAuth>
  );
}
