import { ApiError, ErrorResponse } from "../Errors";
import { CognitoUserPool, CognitoRefreshToken, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import axios, { AxiosResponse } from 'axios';
import { SIGNUP, VERIFY_EMAIL, FORGOT_PASSWORD_INIT, FORGOT_PASSWORD_CONFIRM, RESEND_VERIFY_EMAIL } from "../utils/constants";
import { axiosInstance } from "../utils/axios";

export interface AuthSignUp {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface AuthSignUpVerify {
  email: string;
  code: string;
}

export interface AuthResendVerify {
  email: string;
}

export interface AuthSignIn {
  email: string;
  password: string;
}

export interface AuthPasswordResetInit {
  email: string;
}

export interface AuthPasswordReset extends AuthPasswordResetInit {
  password: string;
  code: string;
}

export interface AuthSignout {
  email: string;
}

const poolData = {
  UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID!,
  ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID!
};

const userPool = new CognitoUserPool(poolData);

export interface CognitoSigninResponse {
  accessToken: string;
  idToken: string;
  refreshToken: string;
}


export async function postSignupFormData(values: AuthSignUp): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(`${SIGNUP}`, values);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError<ErrorResponse>(error) && error.response?.data) {
      const { message, rawErrors } = error.response?.data;
      throw new ApiError(message, rawErrors);
    } else {
      throw new ApiError('Signup API request failed');
    }
  }
}

export async function postSignupVerifyFormData(values: AuthSignUpVerify): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(`${VERIFY_EMAIL}`, values);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError<ErrorResponse>(error) && error.response?.data) {
      const { message, rawErrors } = error.response?.data;
      throw new ApiError(message, rawErrors);
    } else {
      throw new ApiError('Verify API request failed');
    }
  }
}

export async function postResendVerifyFormData(values: AuthResendVerify): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(`${RESEND_VERIFY_EMAIL}`, values);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError<ErrorResponse>(error) && error.response?.data) {
      const { message, rawErrors } = error.response?.data;
      throw new ApiError(message, rawErrors);
    } else {
      throw new ApiError('Verify API request failed');
    }
  }
}

export async function postForgotPasswordInitFormData(values: AuthPasswordResetInit): Promise<string> {
  try {
    const response: AxiosResponse<string> = await axiosInstance.post(`${FORGOT_PASSWORD_INIT}`, values);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError<ErrorResponse>(error) && error.response?.data) {
      const { message, rawErrors } = error.response?.data;
      throw new ApiError(message, rawErrors);
    } else {
      throw new ApiError('Forgot Password API request failed');
    }
  }
}

export async function postForgotPasswordConfirmFormData(values: AuthPasswordReset): Promise<string> {
  try {
    const response: AxiosResponse<string> = await axiosInstance.post(`${FORGOT_PASSWORD_CONFIRM}`, values);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError<ErrorResponse>(error) && error.response?.data) {
      const { message, rawErrors } = error.response?.data;
      throw new ApiError(message, rawErrors);
    } else {
      throw new ApiError('Forgot Password API request failed');
    }
  }
}

export async function cognitoSignin({email, password}: AuthSignIn): Promise<CognitoSigninResponse> {
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password
  });

  const userData = {
    Username: email,
    Pool: userPool
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        const accessToken = result.getAccessToken().getJwtToken();
        const idToken = result.getIdToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken();
        resolve({ accessToken, idToken, refreshToken });
      },
      onFailure: (err) => {
        reject(err);
      }
    });
  });
}

export async function cognitoSignout(): Promise<void> {
  const cognitoUser = userPool.getCurrentUser();
  cognitoUser?.signOut();
  clearLocalStorage();
}

export async function refreshToken(token: string): Promise<string> {
  return new Promise ((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      // clearLocalStorage();
      reject();
      return;
    }

    const refreshToken = new CognitoRefreshToken({ RefreshToken: token });

    cognitoUser.refreshSession(refreshToken, (err, session) => {
      if (err) {
        reject(err);
      } else {
        const refreshedToken = session.getAccessToken().getJwtToken() as string;
        resolve(refreshedToken);
      }
    })
  })
}

export function clearLocalStorage() {
  const clientId = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID!;
  const lastAuthUser = localStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`);

  localStorage.removeItem(`CognitoIdentityServiceProvider.${clientId}.${lastAuthUser}.LastAuthUser`);
  localStorage.removeItem(`CognitoIdentityServiceProvider.${clientId}.${lastAuthUser}.idToken`);
  localStorage.removeItem(`CognitoIdentityServiceProvider.${clientId}.${lastAuthUser}.accessToken`);
  localStorage.removeItem(`CognitoIdentityServiceProvider.${clientId}.${lastAuthUser}.refreshToken`);
  localStorage.removeItem(`CognitoIdentityServiceProvider.${clientId}.${lastAuthUser}.clockDrift`);
  localStorage.removeItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`);
}

export function getStoredJwt(): string | null {
  const clientId = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID!;
  const lastAuthUser = localStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`);

  return localStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.${lastAuthUser}.accessToken`);
}
