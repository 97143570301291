import { atom, useRecoilState } from 'recoil';
import { DomainAlarmModel } from '../../Models/DomainAlarmModel';

export const domainAlarmsAtom = atom<DomainAlarmModel[]>({
  key: 'domainAlarmsAtom',
  default: [],
});


export const useDomainAlarmState = () => {
  const [domainAlarms, setDomainAlarmState] = useRecoilState(domainAlarmsAtom);

  return { domainAlarms, setDomainAlarmState };
};
