export interface ErrorResponse {
  success: false;
  message: string;
  rawErrors: Array<string>;
}

export class ApiError extends Error {
  errors: Array<string> = []

  constructor(message: string, errors?: Array<string>) {
    super(message);
    this.name = 'ApiError';
    if (errors) {
      this.errors = errors
    }
  }
}
