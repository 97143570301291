import {
  Paper,
  PaperProps, Text
} from '@mantine/core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useJwtState } from '../States/jwtState';
import { cognitoSignout } from '../../services/AuthenticationService';
import { LOCALSTORAGE } from '../LocalSorage';



export function Logout (props: PaperProps) {
  const { setJwtState } = useJwtState();
  const navigate = useNavigate();

  useEffect(() => {

    const signout = async () => {
      // await postSignoutFormData();
      await cognitoSignout();
      setJwtState(null);
      navigate('/home');
    }

    Object.values(LOCALSTORAGE).forEach((item) => {
      localStorage.removeItem(item);
    });

    signout();
}, [setJwtState, navigate]);

  return (
    <Paper radius="md" p="xl" {...props} maw={512} ml="lg" style={{ marginLeft: 'auto', marginRight: 'auto'}} >
      <Text size="lg">
        Logging out ...
      </Text>
    </Paper>
  );
}
