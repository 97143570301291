const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8080';
export const API_BASE_URL = `${BASE_URL}/api/v1`;

export const SIGNUP = `/auth/signup`;
export const VERIFY_EMAIL = `/auth/verify-email`;
export const RESEND_VERIFY_EMAIL = `/auth/resend-verification`;
export const SIGNIN = `/auth/signin`;
export const SIGNOUT = `/auth/signout`;
export const FORGOT_PASSWORD_INIT = `/auth/forgot-password-verify"`;
export const FORGOT_PASSWORD_CONFIRM = `/auth/forgot-password-confirm`;
export const SITE_NAME = "BrowseAlarm";

export const DOMAIN_ALARM = "/alarm/domain";

interface DisclosureActions {
  readonly open: () => void;
  readonly close: () => void;
  readonly toggle: () => void;
}

export type Disclosure = readonly [boolean, DisclosureActions];
