import {
  AppShell,
  Burger,
  Group,
  LoadingOverlay,
  NavLink, Stack,
} from '@mantine/core';
import { useState } from 'react';
import {
  Link,
  Route,
  Routes
} from "react-router-dom";
import { SignupPage } from './Authentication/Signup';
import { SignupVerifyPage } from './Authentication/SignupVerify';
import SettingsPage from './SettingsPage';
import { useDisclosure } from '@mantine/hooks';
import { AlarmWrapper } from './Alarms/AlarmWrapper';
import { Logout } from './Authentication/Logout';
import { Signin } from './Authentication/Signin';
import { Logo } from './Logo';

export default function AlarmAppShell() {
  const [overlayVisible] = useState(false);

  const links = [
    { link: '/home', label: 'Home' },
    { link: 'https://browsealarm.com/plugin-test', label: 'Plugin Test'},
    { link: '/signin', label: 'Sign In' },
    { link: '/signup', label: 'Sign Up' },
    { link: '/signup/verify', label: 'Verify Sign Up' },
    { link: '/settings', label: 'Settings' },
    { link: '/logout', label: 'Logout'}
  ];

  const navLinks = links.map((item) => (
    <NavLink key={item.label} label={item.label} component={Link} to={item.link} />
  ));

  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      padding="md">
      <AppShell.Header>
        <Group h="100%" px="md">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <Logo />
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p="md">
        <Stack onClick={toggle}>
          {navLinks}
        </Stack>
      </AppShell.Navbar>
      <AppShell.Main>
        <Routes>
          <Route path="/home" element={<AlarmWrapper />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/signup/verify" element={<SignupVerifyPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={<AlarmWrapper />} />
        </Routes>

      </AppShell.Main>

      <LoadingOverlay
        visible={overlayVisible}
        loaderProps={{ variant: 'dots' }} />
    </AppShell>
  );
}
