import { ActionIcon, rem, Modal } from '@mantine/core';
import { IconSettings } from '@tabler/icons-react';
import { DomainAlarmModel } from '../../Models/DomainAlarmModel';
import { KeywordAlarmModel } from '../../Models/KeywordAlarmModel';
import { useDisclosure } from '@mantine/hooks';
import { DomainAlarm } from './DomainAlarm';

interface ModifyAlarmProps {
  alarm: DomainAlarmModel | KeywordAlarmModel;
}

export function ModifyAlarm ( {alarm} : ModifyAlarmProps) {

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Modal opened={opened} onClose={close} title="Modify alarm" centered>
          <DomainAlarm close={close} alarm={alarm}/>
      </Modal>

      <ActionIcon variant="subtle" color="gray" onClick={open}>
        <IconSettings style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
      </ActionIcon>
    </>
  );
}
