import {
  Paper,
  PaperProps,
  Text,
  Title,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';

export function SignupComplete (props: PaperProps) {

  const navigate = useNavigate();

  setTimeout(() => {
    navigate('/signin');
  }, 3000);

  return (
    <Paper radius="md" p="xl" {...props} maw={512} ml="lg" style={{ marginLeft: 'auto', marginRight: 'auto' }} >
      <Title order = {1}>
        Congratulations! 🎉
      </Title>
      <Text size="xs">
        Routing you to signin in 3 seconds.
      </Text>
    </Paper>
  );
}
