import { isURL } from "class-validator";

export const emailValidator = (val: string) => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val) ? null : 'Invalid email');
export const passwordValidator = (val: string ) => (val.length <= 8 ? 'Password should include at least 8 characters' : null);
export const codeValidator = (val : string) => val.length !== 6 ? "Code is invalid" : null ;

const urlOptions = {
  require_tld: false,
}
export const domainValidator = (val : string) => isURL(val, urlOptions) ? null : "Domain is invalid";
