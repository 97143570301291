import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useJwtLoader from './States/jwtLoader';
import {  LoadingOverlay } from '@mantine/core';

interface Props {
  children: React.ReactNode;
}

const RequireAuth: React.FC<Props> = ({ children }) => {
  // const { jwtValue } = useJwtState();
  const location = useLocation();
  const { jwtValue, isJwtLoading } = useJwtLoader();
  if (isJwtLoading) {
    return <LoadingOverlay visible={isJwtLoading} zIndex={1000} loaderProps={{ color: 'pink', type: 'bars' }}    />
  }

  if (!jwtValue) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

const NoAuth: React.FC<Props> = ({ children }) => {
  const { jwtValue, isJwtLoading } = useJwtLoader();

  if (isJwtLoading) {
    return <LoadingOverlay visible={isJwtLoading} zIndex={1000} loaderProps={{ color: 'pink', type: 'bars' }}    />
  }

  if (jwtValue) {
    return <Navigate to="/home" replace />;
  }

  return <>{children}</>;
};

export { RequireAuth, NoAuth };
