import {
  Button, Group,
  Paper,
  Select,
  Stack, TextInput, Textarea
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { domainValidator } from '../Validators';
import { ApiError } from '../../Errors';
import Alert from '../AlertWrapper';
import { CreateDomainAlarmBody, ModifyDomainAlarmBody, createDomainAlarm, modifyDomainAlarm } from '../../services/AlarmService';
import { useDomainAlarmState } from '../States/domainAlarmState';
import { DomainAlarmModel } from '../../Models/DomainAlarmModel';
import { AlarmType } from '../../Models';
import { updateLocalStorageData } from '../LocalSorage';

interface DomainAlarmProps {
  close: () => void;
  alarm?: DomainAlarmModel
}


export function DomainAlarm ({ close, alarm }: DomainAlarmProps ) {
  const [displayLoader, setDisplayLoader] = useState(false);
  const [apiError, setApiError] = useState<ApiError | any>();

  const { domainAlarms, setDomainAlarmState } = useDomainAlarmState();

  const form = useForm({
    initialValues: {
      domain: alarm?.domain || '',
      alarmType: alarm?.alarmType || '',
      notificationText: alarm?.notificationText || '',
    },
    validate: {
      domain: domainValidator,
    }
  });

  const onSubmit = async () => {
    setDisplayLoader(true);
    handleValues(form.values)
    handleErrors(form.errors);
  }

  const modify = async (formData: any, alarm: DomainAlarmModel): Promise<DomainAlarmModel> => {
    const updated: ModifyDomainAlarmBody = {
      ...alarm,
      ...formData
    }
    const domainAlarm = await modifyDomainAlarm(updated);
    updateLocalStorageData(domainAlarm);
    return domainAlarm;
  }

  const create = async (formData: any): Promise<DomainAlarmModel> => {
    const create: CreateDomainAlarmBody = formData;
    const domainAlarm =  await createDomainAlarm(create);
    updateLocalStorageData(domainAlarm);
    return domainAlarm;
  }

  const handleValues = async (values: typeof form['values']) => {
    const formData = {
      domain: values.domain.trim(),
      alarmType: values.alarmType,
      notificationText: values.notificationText.trim(),
    }
    try {
      if (alarm) {
        const updatedAlarm = await modify(formData, alarm);
        // update the specific object by id
        setDomainAlarmState(domainAlarms.map((alarm) => alarm.id === updatedAlarm.id ? updatedAlarm : alarm));
      } else {
        const response = await create(formData);
        // add the new alarm to the top of the list
        setDomainAlarmState([response, ...domainAlarms]);
      }

      close();
    } catch (err) {
      setApiError(err);
    } finally {
      setDisplayLoader(false);
    }

  }

  const handleErrors = (error: typeof form['errors']) => {
    setApiError(error);
  }

  return (
    <Paper radius="md" maw={512} style={{ marginLeft: 'auto', marginRight: 'auto'}} >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack pt="md">
          <TextInput
            required
            label="Domain"
            placeholder="browsealarm.com"
            value={form.values.domain}
            onChange={(event) => form.setFieldValue('domain', event.currentTarget.value)}
            error={form.errors.domain && 'Invalid domain'}
          />
          <Select
            label="Alarm Type"
            placeholder="Select alarm severity"
            value={form.values.alarmType}
            onChange={(value) => form.setFieldValue('alarmType', value ? value : '')}
            data={Object.keys(AlarmType)}
          />
          <Textarea
            label="Notification Text"
            value={form.values.notificationText}
            onChange={(event) => form.setFieldValue('notificationText', event.currentTarget.value)}
            placeholder="Custom message you want to receive when alarm is triggered"
          />
        </Stack>

        <Group mt="xl">
          <Button type="submit" size='md' fullWidth loading={displayLoader} loaderProps={{type: 'dots'}}>
            {alarm ? 'Save' : 'Add'}
          </Button>
        </Group>
        <Alert messages={apiError?.errors} title={apiError?.message} />
      </form>

    </Paper>
  );
}
