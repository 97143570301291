
import { useDocumentTitle } from '@mantine/hooks';
import { RequireAuth } from './Auth';

export default function CardWrapper() {

  useDocumentTitle(`Alerts`);

  return (
    <RequireAuth>
      Hello SettingsPage
    </RequireAuth>
  )
}
