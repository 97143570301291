import { Anchor, Title } from '@mantine/core';
import { SITE_NAME } from '../utils/constants';

export function Logo( ) {
  return (
    <>
      <Anchor href="/" underline='never'>
        <img src="logo512.png" alt="logo" height="50px" />
      </Anchor>
      <Title order={3}>{SITE_NAME}</Title>
    </>
  );
}
