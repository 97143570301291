import {
  Accordion, Container, Paper,
  PaperProps, Title
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import classes from './AlarmWrapper.module.css';
import { DomainAlarmWrapper } from './DomainAlarmWrapper';
import { KeywordAlarmWrapper } from './KeywordAlarmWrapper';
import { RequireAuth } from '../Auth';

export function AlarmWrapper (props: PaperProps) {

  useDocumentTitle(`Alarms`);
  console.log('inside alarm wrapper');

  return (
    <RequireAuth>
      <Paper radius="md" p="xl" {...props} ml="lg" style={{ marginLeft: 'auto', marginRight: 'auto'}} >
        <Container size="sm" className={classes.wrapper}>
          <Title ta="center" className={classes.title}>
            Alarms
          </Title>

          <Accordion multiple defaultValue={['domain-alarms']}>
            <Accordion.Item className={classes.item} value="domain-alarms">
              <Accordion.Control >Domain Alarms</Accordion.Control>
              <Accordion.Panel>
                <DomainAlarmWrapper />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="keyword-alarms">
              <Accordion.Control>Keyword Alarm</Accordion.Control>
              <Accordion.Panel>
                <KeywordAlarmWrapper />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="location-alarms">
              <Accordion.Control>Location Alarm</Accordion.Control>
              <Accordion.Panel>Location alarm</Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="time-alarms">
              <Accordion.Control>Time Alarm</Accordion.Control>
              <Accordion.Panel>Time alarm</Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Container>
      </Paper>
    </RequireAuth>
  );
}
