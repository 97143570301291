import { refreshToken } from "../services/AuthenticationService";

let globalJwtToken: string | null = null;

export const setGlobalJwtToken = async (token: string | null) => {
  if (token && isTokenExpired(token)) {
    token = await refreshToken(token);
  }
  globalJwtToken = token;
};

const isTokenExpired = (token: string): boolean => {
  try {
    const payload = token.split('.')[1];
    const decodedPayload = atob(payload);
    const { exp } = JSON.parse(decodedPayload);
    const currentUnixTime = Math.floor(Date.now() / 1000);
    return currentUnixTime > exp;
  } catch (error) {
    console.error('Error decoding token', error);
    return true;
  }
}

export const getGlobalJwtToken = () => globalJwtToken;
