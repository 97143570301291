import { Table, Group, Text } from '@mantine/core';
import { AlarmActions } from './AlarmActions';
import { KeywordAlarmModel } from '../../Models/KeywordAlarmModel';

const data = [
  {
    avatar:
      'https://images.unsplash.com/photo-1624298357597-fd92dfbec01d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=250&q=80',
    domain: '*.google.com',
    keywords: ["pokemon", "pikachu", "bulbasaur", "charmander", "squirtle"]
  },
  {
    avatar:
      'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=250&q=80',
    domain: 'yahoo.com',
    keywords: ["hello", "world"]
  },
  {
    avatar:
      'https://images.unsplash.com/photo-1632922267756-9b71242b1592?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=250&q=80',
    domain: 'gmail.com',
    keywords: ["in", "the", "beginning", "there", "was", "the", "word"]
  },
  {
    avatar:
      'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=250&q=80',
    domain: '',
    keywords: ["this", "is", "a", "test"]
  },
  {
    avatar:
      'https://images.unsplash.com/photo-1630841539293-bd20634c5d72?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=250&q=80',
    domain: 'https://browsealarm.com',
    keywords: []
  },
] as unknown as KeywordAlarmModel[];

export function KeywordAlarmWrapper () {
  const rows = data.map((alarm) => (
    <Table.Tr key={alarm.domain}>
      <Table.Td>
        <Group gap="sm">
          <Text fz="sm" fw={500}>
            {alarm.domain || 'any'}
          </Text>
        </Group>
      </Table.Td>
      <Table.Td>
        <Group gap="sm">
          <Text fz="sm" fw={500}>
            {alarm.keywords.join(', ')}
          </Text>
        </Group>
      </Table.Td>
      <Table.Td>
        <AlarmActions alarm={alarm}/>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Table verticalSpacing="sm">
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Domain</Table.Th>
          <Table.Th>Keywords</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  );
}
