import { atom, useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { clearLocalStorage, getStoredJwt } from '../../services/AuthenticationService';
import { setGlobalJwtToken } from '../../utils/token';

const jwtState = atom<string | null>({
  key: 'jwtState',
  default: null,
});

export const useJwtState = () => {
  const [jwtValue, setJwtValue] = useRecoilState(jwtState);

  const setJwtState = async (newValue: string | null) => {
    if (newValue === null) {
      clearLocalStorage();
    }

    setJwtValue(newValue);
    await setGlobalJwtToken(newValue);
  };

    // Initialize JWT state from localStorage on first render
    useEffect(() => {
      const storedJwt = getStoredJwt();
      if (jwtValue == null && storedJwt !== jwtValue) {
        setJwtValue(storedJwt); // if setJwtValue is async

        (async () => {
          await setGlobalJwtToken(storedJwt);
        })();
      }
    }, [jwtValue, setJwtValue]);


  return { jwtValue, setJwtState };
};
